import { useEffect, useState, useRef } from 'react';
import { Tabs as SharedTabs } from '@dealroadshow/uikit';
import TabList from './TabList';
import { ITabList } from './interfaces';

interface IProps {
  tabsList: ITabList[],
  dataTest?: string,
  autoplay?: boolean,
}

const ANIMATION_DURATION = 7000;

const Tabs = ({
  tabsList,
  dataTest = 'featuresTabs',
  autoplay = false,
}: IProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [autoPlayActive, setAutoPlayActive] = useState(autoplay);
  const [activeTab, setActiveTab] = useState<number | string>(tabsList[0].key);
  const { content, key } = tabsList.find((tab) => tab.key === activeTab) ?? {};

  const handleMouseOver = () => {
    setAutoPlayActive(false);
  };

  const handleMouseLeave = () => {
    setAutoPlayActive(true);
  };

  const interval = () => {
    setActiveTab((prevTabKey) => {
      const nextTab = tabsList.findIndex((tab) => tab.key === prevTabKey) + 1;
      return tabsList[nextTab]?.key ?? tabsList[0].key;
    });
  };

  useEffect(() => {
    if (contentRef.current && autoplay) {
      contentRef.current.addEventListener('mouseover', handleMouseOver);
      contentRef.current.addEventListener('mouseleave', handleMouseLeave);

      contentRef.current.addEventListener('touchstart', handleMouseOver);
      contentRef.current.addEventListener('touchend', handleMouseLeave);
    }

    return () => {
      if (contentRef.current && autoplay) {
        contentRef.current.removeEventListener('mouseover', handleMouseOver);
        contentRef.current.removeEventListener('mouseleave', handleMouseLeave);

        contentRef.current.removeEventListener('touchstart', handleMouseOver);
        contentRef.current.removeEventListener('touchend', handleMouseLeave);
      }
    };
  }, []);

  useEffect(() => {
    if (!autoPlayActive) {
      return undefined;
    }

    const tabAutoPlay = setInterval(interval, ANIMATION_DURATION);

    return () => clearInterval(tabAutoPlay);
  }, [autoPlayActive, activeTab]);

  return (
    <SharedTabs
      defaultTab={ activeTab }
      onChange={ setActiveTab }
      dataTest={ dataTest }
    >
      <div>
        <TabList tabs={ tabsList } activeTab={ activeTab } />
        <div ref={ contentRef } data-test={ `${ key }TabContent` }>{ content }</div>
      </div>
    </SharedTabs>
  );
};

export default Tabs;
