import React from 'react';
import { ISlickProps, ReactSlick } from './SlickSliderLoadable';
import style from './slickSlider.scss';

const SlickSlider = ({ children, ...otherProps }: ISlickProps) => (
  <div className={ style.slickSliderEncapsulationGlobalStyles }>
    <ReactSlick { ...otherProps }>
      { children }
    </ReactSlick>
  </div>
);

export default SlickSlider;
