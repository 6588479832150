import React from 'react';
import cn from 'classnames';
import SlickSlider from '@/Framework/UI/Molecules/SlickSlider';

import { feedbacksList as defaultFeedbacksList, slidersSettingsTypes } from './feedbacksConfig';
import { IFeedback } from './interfaces';

import cardStyles from '@dealroadshow/uikit/dist/lib/styles/card.scss';
import shadowStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/shadowOnHover.scss';
import styles from './feedbacks.scss';

interface IProps {
  className?: string,
  feedbackClassName?: string,
  sliderType?: keyof typeof slidersSettingsTypes,
  cardStyle?: 'default' | 'modern',
  feedbacksList?: IFeedback[],
  imageComponent?: React.ComponentType<any>,
}

const Feedbacks = ({
  className,
  feedbackClassName,
  sliderType = 'default',
  cardStyle = 'default',
  feedbacksList = defaultFeedbacksList,
  imageComponent: ImageComponent,
}: IProps) => {
  const sliderSettings = slidersSettingsTypes[sliderType];

  return (
    <div className={ cn(styles.feedbacks, className) }>
      <div className={ styles.feedbacksWrp }>
        <SlickSlider { ...sliderSettings }>
          { feedbacksList.map(({ imageSrc, imageAlt, feedbackText, feedbackAuthor, imageWidth, imageHeight }) => (
            <div
              className={ cn(styles.feedback, feedbackClassName) }
              key={ feedbackAuthor }
            >
              <div
                className={ cn(
                  styles.feedbackCard,
                  {
                    [cardStyles.cardContainer]: cardStyle === 'default',
                    [shadowStyles.darkShadowOnHover]: cardStyle === 'default',
                    [styles.modernCard]: cardStyle === 'modern',
                  },
                ) }
              >
                <div className={ styles.feedbackLogo }>
                  { ImageComponent ? (
                    <ImageComponent
                      src={ imageSrc }
                      title={ imageAlt }
                      alt={ imageAlt }
                      width={ imageWidth }
                      height={ imageHeight }
                    />
                  ) : (
                    <img
                      src={ imageSrc }
                      title={ imageAlt }
                      alt={ imageAlt }
                    />
                  ) }
                </div>
                <div className={ styles.feedbackText }>
                  { feedbackText }
                </div>
                <div className={ styles.feedbackAuthor }>
                  { feedbackAuthor }
                </div>
              </div>
            </div>
          )) }
        </SlickSlider>
      </div>
    </div>
  );
};

export default Feedbacks;
